<template>

<div>

       <b-modal
          ref="modalVerificar"
          centered
          hide-footer
          size="sm"
          title="Verificación de tarjeta de pago"
        >
      <div class="d-block text-center">

       

          <b-form autocomplete="off"  @submit.prevent="verifyCard2">
            <b-row>

    
           

              <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >

                                <p style="text-align: center;margin-bottom: 5px;font-weight: bold;">Ingrese el monto cobrado a su tarjeta:
                                
                                <feather-icon
                                icon="HelpCircleIcon"
                                size="16"
                                v-b-popover.hover.bottom.html="'Para saber el monto cobrado, revisa los pagos salientes de tu tarjeta en la fecha y hora en la que hizo este pago. Recuerda que es un monto menor a $1.00 USD'"
                                  title="Monto cobrado"
                                style="margin-right: 5px;cursor:pointer;"
                                /> </p>

                                
                      <AutoNumericVue
                        v-model="mount"
                        required
                        type="text" inputmode="decimal"
                        @blur="onBlurMount"
                        :autofocus="false"
                        :readonly="isActive2"
                        class="autonumeric_input"
                        :options="json_opciones"
                        :placeholder="'$0.00'"
                    ></AutoNumericVue>
      
                    <p style="margin-bottom:10px; text-align: center;font-size:12px; font-style:italic">El valor ingresado debe ser en dólares americanos, caso contrario, realizar la conversión de su moneda local a USD. </p>

                                  
            

           </b-col>

            


               <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                        >

                          <p
                            class="textLabel"
                            style="text-align: center;margin-top:10px; margin-bottom: 10px;"
                          >
                            Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

                          <PincodeInput
                            v-model="pinVerificar"
                            required
                            placeholder="-"
                            :length="6"
                            :autofocus="false"
                            :secure="true"
                            :readonly="isActive2"
                          />

                        </b-col>

                        <b-col
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    align="center"
                                  >

                                  
                <b-alert
                          variant="primary"
                          show
                        >
                          <div class="alert-body">
                      

                                             
                            <p style="text-align: center;margin-bottom: 0px;margin-top:10px">Número de intentos restantes: <b style="font-size: 18px;margin-right: 5px;"> {{intentos}} intentos</b>
              

                            <feather-icon
                              icon="HelpCircleIcon"
                              size="18"
                              v-b-popover.hover.bottom.html="'Dispone de solo 2 intentos para ingresar el monto cobrado, en el caso de fallar los 2 intentos se procederá a eliminar la tarjeta registrada.'"
                              title="Intentos de verificación"
                              style="margin-right: 5px;cursor:pointer;"
                              /> </p>
                      
                          </div>
                        </b-alert>

       
                        


                    </b-col>
                 


  
           


          
                        <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >

                          <b-button
                            id="btn_verificar"
                            ref="btn_verificar"
                            style="margin-top:15px; margin-bottom: 15px;"
                            type="submit"
                            variant="primary"
                            :disabled="isActive2"
                            block
                          >

                            Verificar tarjeta
                          </b-button>

                        </b-col>


                     
              


          </b-row>

           </b-form>

      </div>

    </b-modal>

        <b-modal
          ref="modalEliminar"
          centered
          hide-footer
          title="Confirmación de eliminación de tarjeta"
        >
      <div class="d-block text-center">

       

          <b-form autocomplete="off"  @submit.prevent="eliminarTarjeta">
            <b-row>

                         <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >
                      <p style="font-weight: bold;     font-size: 14px; margin-bottom: 15px;">¿Estás seguro de eliminar esta tarjeta?</p>

                    </b-col>


           

                <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >

                    <div class="table-responsive">
                         <table class="table table-bordered" style="text-align: center;">

                         
                          <tbody>
                            <tr>
                   
                              <td class="background_table">Marca:</td>
                              <td>{{brandEliminar}}</td>
                            
                            </tr>

                              <tr>
                   
                              <td class="background_table">Número:</td>
                              <td>{{numeroEliminar}}</td>
                            </tr>

                             <tr>
                   
                              <td class="background_table">Estado:</td>
                              <td>{{statusEliminar}}</td>
                            </tr>

                     
                          
                          </tbody>

                        </table>
                        </div>
                 

                </b-col>

          

         

                      <b-col
                          sm="12"
                          md="12"
                          lg="2"
                          xl="2"
                          align="center"
                        >
                        </b-col>

               <b-col
                          sm="12"
                          md="12"
                          lg="8"
                          xl="8"
                        >

                          <p
                            class="textLabel"
                            style="text-align: center;margin-bottom: 10px;"
                          >
                            Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

                          <PincodeInput
                            v-model="pinEliminar"
                            required
                            placeholder="-"
                            :length="6"
                            :autofocus="false"
                            :secure="true"
                            :readonly="isActiveEliminar"
                          />

                        </b-col>
                   <b-col
                          sm="12"
                          md="12"
                          lg="2"
                          xl="2"
                          align="center"
                        >
                        </b-col>


       

           
                <b-col
                          sm="12"
                          md="12"
                          lg="3"
                          xl="3"
                          align="center"
                        >

           </b-col>



          
                        <b-col
                          sm="12"
                          md="12"
                          lg="6"
                          xl="6"
                          align="center"
                        >

                          <b-button
                            id="btn_eliminar"
                            ref="btn_eliminar"
                            style="margin-top:15px; margin-bottom: 15px;"
                            type="submit"
                            variant="primary"
                            :disabled="isActiveEliminar"
                            block
                          >

                            Eliminar tarjeta
                          </b-button>

                        </b-col>

                             <b-col
                          sm="12"
                          md="12"
                          lg="3"
                          xl="3"
                          align="center"
                        >

                     </b-col>

                     
              


          </b-row>

           </b-form>

      </div>

    </b-modal>

       <div
                v-if="isProfileVerificated"
                align="center"
              >
 

                 <b-row
                      v-if="loading"
                      align="center"
                    >

                 

                        <b-col  cols="4" sm="4" md="4" xl="4" lg="4">


                        </b-col>

                        <b-col  cols="4" sm="4" md="4" xl="4" lg="4">

                        <b-skeleton width="100%"  height="30px"></b-skeleton>

                        </b-col>

                        <b-col  cols="4" sm="4" md="4" xl="4" lg="4">


                        </b-col>

                        <b-col  cols="4" sm="4" md="4" xl="3" lg="3">


                            </b-col>

                            <b-col  cols="4" sm="4" md="4" xl="6" lg="6">

                            <b-skeleton width="100%"  height="30px"></b-skeleton>

                            </b-col>

                            <b-col  cols="4" sm="4" md="4" xl="3" lg="3">


                            </b-col>

                        <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">




                                          <b-skeleton-table
                                      :rows="2"
                                      :columns="4"
                                      :table-props="{ bordered: true, striped: true }"
                                    ></b-skeleton-table>

                        </b-col>

                        <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


                        </b-col>

                        <b-col  cols="6" sm="6" md="6" xl="6" lg="6">

                        <b-skeleton width="100%"  height="30px"></b-skeleton>

                        </b-col>

                        <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


                        </b-col>

                        <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


                        </b-col>

                        <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
                        <b-skeleton width="100%"  height="10px"></b-skeleton>


                        </b-col>

                        <b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
                        <b-skeleton width="50px" type="avatar"   height="50px"></b-skeleton>


                        </b-col>

                        <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
                        <b-skeleton width="100%"  height="10px"></b-skeleton>


                        </b-col>


                        <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


                        </b-col>
                  </b-row>



                 
                        <table  v-else-if="code200" class="table tablesorter"  id="datatable" style="  overflow: auto;" >
                              <thead class="thead-light">
                                <tr>
                                  <th data-priority="1">Número</th>
                                 
                                  <th>Marca</th>
                                  
                                  <th>Estado</th>
                                  <th data-priority="2">Acción</th>
                                  
                                </tr>
                              </thead>
                              <tbody>


                                <tr v-for="card in cards" :key="card.id">
                                <td>**** **** **** {{ card.lastNumber }}</td>
                            
                                  <td >  <img :alt="card.type" :src="card.logoBrand"></td>
                              
                                  
                                  <td v-if="card.TDCVerify">
                                     <b-badge variant="success">
                                        Verificada
                                      </b-badge>
                                  
                                  </td>
                                  <td v-else>

                                     <b-badge variant="danger">
                                        No verificada
                                      </b-badge>
                                   
                                    
                                    </td>

                                    <td>

                                      <div v-if="card.TDCVerify == false">

                                        <div v-if="isMobile" align="center" >

                                          <feather-icon
                                                  icon="MoreVerticalIcon"
                                                  size="25"
                                                  class="MoreVerticalIcon"
                                                  @click="openModalBottom(card.id,card.lastNumber, card.type, card.TDCVerify )"
                                                
                                                  />



                                              
                                      


                                            </div>

                                            <div v-else  align="center"  >

                                              <b-dropdown  dropleft variant="light" size="sm"  no-caret>
                                                <template  #button-content>

                                                <feather-icon
                                                  icon="MoreVerticalIcon"
                                                  size="25"
                                                  class="MoreVerticalIcon"
                                                  />
                                                  


                                                </template>
                                                <b-dropdown-item @click="opciones('verificar',card.id,card.lastNumber, card.type, card.TDCVerify)">
                                                  <feather-icon
                                                  icon="CheckCircleIcon"
                                                  size="15"
                                                  style="margin-right: 10px;"
                                                  />

                                                  Verificar tarjeta
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="opciones('eliminar',card.id,card.lastNumber, card.type, card.TDCVerify )">
                                                  
                                                  <feather-icon
                                                  icon="Trash2Icon"
                                                  size="15"
                                                  style="margin-right: 10px;"
                                                  />
                                                  Eliminar tarjeta</b-dropdown-item>
                                              </b-dropdown>

                                            </div>



                                      </div>

                                              <feather-icon
                                              v-else
                                            icon="Trash2Icon"
                                            size="21"
                                            style="cursor:pointer"
                                            @click="opciones('eliminar',card.id,card.lastNumber, card.type, card.TDCVerify )"
                                            />



                                        

                                      </td>
                                 

                                </tr>
                              </tbody>
                            </table>

         
  

                      <div
                      v-else-if="notCards"
                      align="center"
               
                    >

                          <img

                          alt="SSin tarjetas"
                          src="/img/noCreditCard.svg"
                        >

                        <p class="hasNotificacion">
                          No tienes registrado ninguna tarjeta de pagos
                        </p>

                   

                     

                    </div>

                    <div
                      v-else
                      align="center"
                    >

                      <feather-icon
             icon="FrownIcon"
             size="50"
             />
                      <p class="errorTexto">
                        Ocurrió un error al cargar los datos
                      </p>

                      <b-button
                        id="btn_refresh"
                        ref="btn_refresh"
                        variant="primary"
                        @click="refreshListado"
                      >

                        Intentar de nuevo

                      </b-button>

                    </div>

        
            </div>

            
              <div
                v-else
                align="center"
              >

              
              <img

              alt="Usuario no verificado"
              src="/img/verifyAccount.svg"
              >

              <p style="text-align: center; font-size:14px; line-height: 25px;margin-top: 20px;">Para utilizar esta funcionalidad su cuenta debe estar verificada.</p>
              <b-button
          id="btn_refresh"
          ref="btn_refresh"
          variant="primary"
          :to='{ name: "perfil", hash: "#verificacion"}'
        >

          Verificar mi cuenta

        </b-button>

              </div>
              <vue-bottom-dialog v-if="dialog" :indentColor="indentColor" :topRadius="radius" v-model="dialog" :height="180"  :overlayColor="overlay">

                <b-row>   

                <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 

                <h4 style="margin: 0;" class="font-weight-bolder">
                    Opciones disponibles

                </h4>

                </b-col>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12"> <hr></b-col>

                <div class="opciones_cripto">
                    <label for="Tarjeta"> 

                      <feather-icon
                  icon="CheckCircleIcon"
                  size="20"
                  style="margin-right: 10px"
                  /> Verificar tarjeta

                    </label>
                    <input id="Tarjeta"  type="radio" @click="openOpciones('verificar')"  value="Tarjeta" name="radio" />
                </div>

                <div class="opciones_cripto">
                    <label for="Eliminar"> 

                      <feather-icon
                  icon="Trash2Icon"
                  size="20"
                  style="margin-right: 10px"
                  />Eliminar tarjeta

                    </label>
                    <input id="Eliminar"  type="radio" @click="openOpciones('eliminar')"  value="Eliminar" name="radio" />
                </div>




                </b-row>  



                </vue-bottom-dialog>
 

  </div>

</template>

<script>
import {
  BButton,BBadge, BForm,BDropdown,BDropdownItem, VBPopover, BFormSelect, BSkeletonTable, BRow, BCol, BCard, BSkeleton, BAlert
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import PincodeInput from 'vue-pincode-input'
import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
import "jquery/dist/jquery.min.js";

import $ from "jquery";
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
export default {
  components: {
    PincodeInput,
    BAlert,
    AutoNumericVue,
    vSelect,
    BFormSelect,
    BSkeleton,
    BButton,
    BDropdown,
    BForm,
    BBadge,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BSkeletonTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId','tokenAuth'],
  data() {

      const datos_json = JSON.parse(this.$userGlobal);


    return {
      code200: false,
      loading: true,
      notCards:false,
      isMobile:this.$isMobile,
        dialog:false,
        color: "#242526",
      overlay:"#000000e6",
      radius:"15px",
       indentColor:"#b0b3b8",
      pin: '',
      body: document.getElementsByTagName('body')[0],
      isProfileVerificated: datos_json.isProfileVerificated,
          cards:[],
          json_opciones:{},
            pinVerificar:"",
             pinEliminar:"",
            isActive2:false,
            isActiveEliminar:false,
            intentos:0,
              mount:"",
                   idCard:"",
                   statusEliminar:"",
                   numeroEliminar:"",
                   brandEliminar:"",
                   idCard:"",lastNumber:"", type:"", TDCVerify:false

    }
  },
  computed: {

  },
  watch: {

    dialog(newValue) {

     
        if(newValue){
          this.body.classList.add("overflow-hidden");
        }else{
          this.body.classList.remove("overflow-hidden");
        }



        },

  },
  mounted() {

    this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1, modifyValueOnWheel: false };




    this.loadTable()
  },
  methods: {

     loadTable(){



      const dataEncripted = this.$encryptBackoffice.encrypt(this.userId)

      this.$https.post('/card/listAllCards/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {
         this.loading=false;
 
                      if(response.data["code"] == 200){
                      
                        this.code200=true;
                
                       

                        this.cards= response.data["cards"];
 
                 
                                  setTimeout(() => {
                                    $("#datatable").DataTable({
                                      responsive: true,
                                      columnDefs: [
                                        { responsivePriority: 1, targets: 0 },
                                        { responsivePriority: 2, targets: -1 }
                                        ],
                                        "ordering": false,
                                      lengthMenu: [
                                        [5,10, 25, 50, -1],
                                        [5,10, 25, 50, "All"],
                                      ],
                                      pageLength: 5, 
                                         "language": {
                                              "search": "Buscar:",
                                              "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
	                                            "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                              "searchPlaceholder": "Ingrese algún dato",
                                              "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                              "processing": "Buscando. Espere por favor...",
                                              "sLengthMenu":     "Mostrar _MENU_ registros",
                                              "oPaginate": {
                                                      "sFirst":    "Primero",
                                                      "sLast":     "Último",
                                                      "sNext":     "Siguiente",
                                                      "sPrevious": "Anterior"
                                                  },

                                              }
                                    });
                                  });

                     
  
                          
                        }else{


                            


                                  if (response.data.code == 401) {

                                    
                          this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

                                    
                                    localStorage.removeItem('userData')



                                    
                                    

           

                                    
                                    

                                    // Redirect to login page
                                    this.$router.push({ name: 'auth-login' })
                                  } else {

                                     if (response.data.code == 404) {



                                      this.notCards=true;

                                    }else{

                                         this.$toast.error(response.data.message, {
                                          position: 'top-right',
                                          timeout: 3010,
                                          closeOnClick: true,
                                          pauseOnFocusLoss: false,
                                          pauseOnHover: true,
                                          draggable: true,
                                          draggablePercent: 0.4,
                                          showCloseButtonOnHover: false,
                                          hideProgressBar: true,
                                          closeButton: 'button',
                                          icon: true,
                                          rtl: false,
                                        })

                                        this.code200 = false

                                    }
                                  


                                  }

                        }
                 }).catch((error) => {

                           this.code200=false;

                      this.$toast.error("Ocurrió un error inesperado", {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

                     
                 });

    },

    refreshListado() {
      this.$eventBus.$emit('reiniciarListadoTarjetas')
    },
    openModalBottom(id,lastNumber, type, TDCVerify){
        this.idCard =id;
        this.lastNumber=lastNumber;

          this.type=type;
          this.TDCVerify=TDCVerify;

              this.dialog=true;

          },

          openOpciones(type){
            if(type==="verificar"){
              this.checkStatusCard();

            }else{
              this.deleteCard();

            }

          },

    opciones(tipo,id,lastNumber, type, TDCVerify){

        this.idCard =id;


        if(tipo==="verificar"){

          this.checkStatusCard();

        }else{

          this.lastNumber=lastNumber;

          this.type=type;
          this.TDCVerify=TDCVerify;



          this.deleteCard();

        }

        },

    deleteCard(){

      this.dialog=false;



       if(this.TDCVerify){

         this.statusEliminar= "Verificado";

       }else{

         this.statusEliminar= "No verificado";
        
       }

        
                   this.numeroEliminar="**** **** ****"+this.lastNumber ;
                   this.brandEliminar= this.type;
       this.$refs.modalEliminar.show()

    },
    checkStatusCard(){

           this.$swal({

              html:
                              '<img style="margin-left:15px; width:20px; " src="/img/loading.svg" alt="Loading" /> Verificando estado, espere',
              showCloseButton: false,
              showCancelButton: false,
              focusConfirm: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              position: 'center',
            })


          const userId_json = { userId: this.userId, idCard: this.idCard }
    const user_string = JSON.stringify(userId_json)

    const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

    this.$https.post('/card/checkStatusCard/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
      this.dialog=false;
       this.$swal.close()
      if (response.data.code == 200) {
          this.pinVerificar= "";
          this.mount="";

         this.intentos=response.data.intentos;

        this.$refs.modalVerificar.show()
      } else {
        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        if (response.data.code == 401) {
          
          localStorage.removeItem('userData')



          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {

            if (response.data.code == 504) {

                   this.$eventBus.$emit('reiniciarListadoTarjetas');


          

            }
          
        }
      }
    }).catch(error => {
      this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    })

    },

    eliminarTarjeta(event){

      

      event.preventDefault();

      const userId_json = { userId: this.userId, pin: this.pinEliminar, idCard: this.idCard}
      const user_string = JSON.stringify(userId_json);
          

      this.isActiveEliminar = true

       const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

      document.getElementById('btn_eliminar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Eliminando, espere'

 

      this.$https.post('/card/deleteCard/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        this.isActiveEliminar = false

        document.getElementById('btn_eliminar').innerHTML = 'Eliminar tarjeta';
 
     
        if (response.data.code == 200) {

   
          this.pinEliminar= "";
          this.idCard="";

               this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
               
 
            

          this.$refs.modalEliminar.hide();

           this.$eventBus.$emit('reiniciarListadoTarjetas');


        } else {

            this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          }else{

             if (response.data.code == 503) {

                  this.pinEliminar="";

             }

          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })

    },
    onBlurMount(){

      
    

        if(this.mount == null || this.mount == "null" || this.mount ==""){

          if(Number(this.mount) == Number("0")){

                this.mount="";

                this.$toast.error("El monto ingresado debe ser mayor a $0.00 USD", {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })


                }

      

        }
    },
   verifyCard2(event){

      event.preventDefault();

      const userId_json = { userId: this.userId, pin: this.pinVerificar, idCard: this.idCard, mount: this.mount }
      const user_string = JSON.stringify(userId_json);
          

      this.isActive2 = true

            const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

      document.getElementById('btn_verificar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Verificando, espere'

 

      this.$https.post('/card/verifyCard/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        this.isActive2 = false

        document.getElementById('btn_verificar').innerHTML = 'Verificar tarjeta';
 
     
        if (response.data.code == 200) {

   
          this.pinVerificar= "";
          this.mount="";
          this.idCard="";
           this.intentos="";

               this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
               
 
            

          this.$refs.modalVerificar.hide();
    

                this.$eventBus.$emit('reiniciarListadoTarjetas');

                if(Number(response.data.eqpoints) > Number("0")){

            
                    this.eqcoins(response.data.eqpoints, "asociar una nueva tarjeta de pago a tu perfil", "");


                    }



        } else {

            this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          }else{

             if (response.data.code == 504) {

                  if(response.data.intentosVerificacion <= 0){

               
                        this.pinVerificar= "";
                        this.mount="";
                        this.idCard="";

                        this.$refs.modalVerificar.hide();

                    this.$eventBus.$emit('reiniciarListadoTarjetas');


                    }else{

                        this.intentos=response.data.intentosVerificacion;


                    }

             }

          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
      

    },
  },
}
</script>

<style lang="scss">







</style>
