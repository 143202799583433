<template>
  <b-card>

   

          <b-tabs
            class="row"
          content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          nav-class="nav-left"
         align="center"
        >



          <b-tab active>

            <template #title>
              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block"><svg class="icons_colors" width="21" style="width: 21px;" height="21" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.403 5.633A8.919 8.919.0 0 0 12.053 3c-4.948.0-8.976 4.027-8.978 8.977.0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981.0 0 0 4.29 1.093h.004c4.947.0 8.975-4.027 8.977-8.977a8.926 8.926.0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446.0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448.0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413.0 0 1 5.275 2.188 7.42 7.42.0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65.0 0 0-.429-.008.826.826.0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"/></svg> Por Whatsapp</span>
              <span class="titulo_menu d-block d-sm-block d-md-none d-lg-none d-xl-none"><svg class="icons_colors"  style="width: 21px;" width="21" height="21" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.403 5.633A8.919 8.919.0 0 0 12.053 3c-4.948.0-8.976 4.027-8.978 8.977.0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981.0 0 0 4.29 1.093h.004c4.947.0 8.975-4.027 8.977-8.977a8.926 8.926.0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446.0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448.0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413.0 0 1 5.275 2.188 7.42 7.42.0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65.0 0 0-.429-.008.826.826.0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"/></svg> Por Whatsapp</span>
            </template>

             <b-row>

             <b-col
                    cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                  v-if="loading"
                  align="center"
                >

                <b-row>

                  <b-col cols="4" sm="4" md="4" lg="4" xl="4">



                  </b-col>

                    <b-col cols="4" sm="4" md="4" lg="4" xl="4" align="center">

                  <b-skeleton type="avatar" width="60px" height="60px"></b-skeleton>


                  </b-col>

                  <b-col cols="4" sm="4" md="4" lg="4" xl="4">



                  </b-col>

                  <b-col cols="2" sm="2" md="2" lg="2" xl="2"></b-col>

                  <b-col cols="8" sm="8" md="8" lg="8" xl="8" style="margin-top: 10px;">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>

                    </b-col>

                    <b-col cols="2" sm="2" md="2" lg="2" xl="2"></b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">


                      <b-skeleton width="100%"  height="40px"></b-skeleton>


                    </b-col>

                    <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 15px;">

                      <b-skeleton width="100%"  height="10px"></b-skeleton>

                      <b-skeleton width="100%"  height="30px"></b-skeleton>

                      </b-col>

                      <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 15px;">

                      <b-skeleton width="100%"  height="20px"></b-skeleton>

                      <table width="100%">
                                  <tr>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  </tr>
                                </table>

                      </b-col>

                      <b-col
                              cols="4"
                                  sm="4"
                                  md="4"
                                  lg="4"
                                  xl="4"
                                > </b-col>


                              <b-col
                              cols="4"
                                  sm="4"
                                  md="4"
                                  lg="4"
                                  xl="4"
                                >

                                <b-skeleton width="100%"  height="40px"></b-skeleton>
                              
                              </b-col>


                              <b-col
                              cols="4"
                                  sm="4"
                                  md="4"
                                  lg="4"
                                  xl="4"
                                > </b-col>


                  


                </b-row>

                </b-col>

                 <b-col
                      v-else
                      align="center"
                      style="padding: 0;"
                    >

                      <b-col
                        v-if="hasWhatsapp"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        align="center"
                      >

                      
                      <b-modal
                        ref="modalEliminar"
                        centered
                        hide-footer
                        title="Verificación de celular"
                        size="sm"
                      >
                        <div class="d-block text-center">

                          <b-form
                            autocomplete="off"
                            @submit.prevent="onSubmitDelete"
                          >

                            <b-row>

                              <b-col
                                sm="12"
                                md="12"
                                lg="12"
                                xl="12"
                                style="margin-bottom: 10px;"
                              >

                                <p
                                  class="textLabel"
                                  style="text-align: center;"
                                >
                                  Ingrese el código de verificación enviado a su celular
                                </p>

                                <PincodeInput
                                  v-model="pinCode"
                                  placeholder="-"
                                  :length="6"
                                  :autofocus="false"
                                  :secure="false"
                                  :readonly="isActiveEliminacion"
                                  required
                                />

                              </b-col>

                              <b-col
                                sm="12"
                                md="12"
                                lg="12"
                                xl="12"
                              >

                                <p
                                  class="textLabel"
                                  style="text-align: center;"
                                >
                                  Ingrese su código PIN (*): <feather-icon
                                icon="HelpCircleIcon"
                                size="16"
                                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                title="Código PIN de seguridad"
                                style="margin-right: 5px;cursor:pointer;"
                                /> </p>

                                <PincodeInput
                                  v-model="pinEliminacion"
                                  placeholder="-"
                                  :length="6"
                                  :autofocus="false"
                                  :secure="true"
                                  :readonly="isActiveEliminacion"
                                  required
                                />

                              </b-col>

                              <b-col
                                sm="12"
                                md="12"
                                lg="12"
                                xl="12"
                                style="margin-top: 15px;"
                              >

                                <b-button
                                  block
                                  id="btn_eliminar_celular"
                                  ref="btn_eliminar_celular"
                                  type="submit"
                                  variant="primary"
                                  :disabled="isActiveEliminacion"
                                >

                                  Confirmar eliminación

                                </b-button>

                              </b-col>

                            </b-row>

                          </b-form>

                        </div>

                      </b-modal>

                        <img

                          alt="Todo ok"
                          src="/img/ok.svg"
                        >

                        <p class="hasNotificacion">
                          Ya tienes configurado notificaciones por whatsapp
                        </p>
                        <b-alert
                          variant="primary"
                          show
                        >
                          <div class="alert-body">
                            <span> El número {{ phoneWhatsapp }} se encuentra actualmente habilitado para recibir notificaciones de pagos por whatsapp.</span>
                          </div>
                        </b-alert>

                        <b-row>
                          <b-col
                              sm="12"
                              md="12"
                              lg="3"
                              xl="3"
                              align="center"
                            />

                            <b-col
                              sm="12"
                              md="12"
                              lg="6"
                              xl="6"
                              align="center"
                            >

                              <b-button
                                style="margin-top:10px"
                                @click="deletePhone()"
                                variant="outline-danger"
                                block
                              >
                              <feather-icon
                              icon="TrashIcon"
                              size="14"
                              style="margin-right: 5px;"
                              />
                                Eliminar número celular

                              </b-button>

                            </b-col>

                            <b-col
                              sm="12"
                              md="12"
                              lg="3"
                              xl="3"
                              align="center"
                            />
                        </b-row>

                        <hr>

                        <p class="hasNotificacion" style="margin-top:10px">
                          ¿Deseas modificar tu número de whatsapp?
                        </p>

               

                     


                      </b-col>

                      <b-col
                        v-else
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        align="center"
                        style="padding: 0;"
                      >

                        <img

                          alt="No tiene whatsapp"
                          src="/img/no_phone.svg"
                        >

                        <p class="hasNotificacion">
                          No tienes configurado un número celular con whatsapp
                        </p>



                      

                      </b-col>

                      <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        align="center"
                      >

                      <b-form
                          autocomplete="off"
                          @submit.prevent="onSubmit2"
                        >

                          <b-row>

                            <b-col
                              sm="12"
                              md="12"
                              lg="6"
                              xl="6"
                              align="center"
                            >

                              <p
                                class="textLabel"
                                style="text-align: center;margin-top: 10px; margin-bottom: 10px;"
                              >
                                Ingrese un número celular (*):
                              </p>

                              <MazPhoneNumberInput
                                v-model="phoneNumber"
                                type="tel"
                                style="margin-bottom: 10px;"
                                default-country-code="EC"
                                no-flags
                                no-example
                                show-code-on-list
                                size="md"
                                :translations="{
                                  countrySelectorLabel: 'Codigo',
                                  countrySelectorError: 'Seleccione un pais',
                                  phoneNumberLabel: 'Celular:',
                                }"
                                @update="results = $event"
                              />

                            </b-col>

                            <b-col
                              sm="12"
                              md="12"
                              lg="6"
                              xl="6"
                              align="center"
                            >

                              <p
                                class="textLabel"
                                style="text-align: center;margin-top: 10px; margin-bottom: 10px;"
                              >
                                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

                              <PincodeInput
                                v-model="pinPhone"
                                placeholder="-"
                                :length="6"
                                :secure="true"
                                required
                                :autofocus="false"
                              />

                            </b-col>

                            <b-col
                              sm="12"
                              md="12"
                              lg="3"
                              xl="3"
                              align="center"
                            />

                            <b-col
                              sm="12"
                              md="12"
                              lg="6"
                              xl="6"
                              align="center"
                            >

                              <b-button
                                id="btn_number"
                                ref="btn_number"
                                style="margin-top:10px"
                                type="submit"
                                variant="primary"
                                block
                                :disabled="isActivePhone"
                              >

                               Registrar número celular

                              </b-button>

                            </b-col>

                            <b-col
                              sm="12"
                              md="12"
                              lg="3"
                              xl="3"
                              align="center"
                            />

                          </b-row>

                        </b-form>

           
                      </b-col>

                            </b-col>


                        

                      </b-row>

      

          </b-tab>

          <b-tab @click="notificacionesEmail">

            <template #title>


              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block"><feather-icon
                        icon="MailIcon"
                        size="20"
                        class="color_icon"
                        /> Por email</span>
              <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none"><feather-icon
                        icon="MailIcon"
                        size="20"
                        class="color_icon"
                        />Por email</span>
            </template>

            <notificaciones-email
              v-if="ifEmail"
              :key="componentKeyEmail"
              :user-id="userId"
              :token-auth="tokenAuth"
            />

          </b-tab>

        </b-tabs>


    <b-modal
      ref="modalPhone"
      centered
      hide-footer
      size="sm"
      title="Código de verificación de celular"
    >
      <div class="d-block text-center">

        <p class="p_login">
          Ingrese el código de verificación enviado a su celular
        </p>

        <PincodeInput
          v-model="sendCode"
          placeholder="-"
          :length="6"
          :autofocus="false"
          :secure="false"
        />

      </div>

    </b-modal>



  </b-card>
</template>

<script>
import {
  BButton, BForm, BAlert,BTabs, BTab, BFormInput, VBPopover, BFormGroup, BSkeleton, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import PincodeInput from 'vue-pincode-input'
import NotificacionesEmail from './NotificacionesEmail.vue'
import Vue from 'vue'

export default {
  components: {
    PincodeInput,
    BButton,
    
        NotificacionesEmail,
    BForm,
    BAlert,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSkeleton,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId','tokenAuth'],
  data() {
    return {
      ifEmail: false,
            componentKeyEmail: 0,
      hasWhatsapp: false,
      loading: true,
      phone: '',
      phoneWhatsapp: '',
      isActivePhone: false,
      results: null,
      phoneNumber: null,
      sendCode: '',
      pinPhone: '',
      pinCode:"",
      pinEliminacion:"",
      isActiveEliminacion:false,

    }
  },
  computed: {

  },
  watch: {
    results(newValue) {
      if (newValue.isValid) {
        this.isActivePhone = false
        this.phoneNumber = newValue.e164
      } else {
        this.isActivePhone = true
        this.phoneNumber = ''
        this.sendCode = ''
      }
    },
    sendCode(newValue) {
      if (newValue.length == 6) {
        this.verifyCode(newValue)
      }
    }

  },
  mounted() {


    this.$eventBus.$on('reiniciarEmail', () => {
      this.componentKeyEmail += 1
    })

    this.checkHasPhone();

   
  },
  methods: {

    checkHasPhone(){

      this.loading=true;
      const userId_json = { userId: this.userId }
        const user_string = JSON.stringify(userId_json)

        const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

        this.$https.post('/profile/checkHasPhone/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {

          if (response.data.code == 200) {
          


            this.phone = response.data.datos.phone;
            this.phoneWhatsapp = response.data.datos.phoneWhatsapp;
            this.hasWhatsapp = response.data.datos.hasWhatsapp;

            this.loading = false


          } else {
     

            if (response.data.code == 401) {

              this.$toast.error(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
              
              localStorage.removeItem('userData')


              

              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            } else {
              this.checkHasPhone();

            }
          }
        }).catch(error => {

          this.checkHasPhone();

        })
    },

    
    onSubmitDelete(event) {
      event.preventDefault()

      const userId_json = { userId: this.userId, pin: this.pinEliminacion, code: this.pinCode }
      const user_string = JSON.stringify(userId_json)

      this.isActiveEliminacion = true

      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

      document.getElementById('btn_eliminar_celular').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Eliminando, espere'

      this.$https.post('/profile/deleteWhatsapp/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        this.isActiveEliminacion = false

        document.getElementById('btn_eliminar_celular').innerHTML = 'Confirmar eliminacion'

        if (response.data.code == 200) {

          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          this.pinCode = ''
          this.pinEliminacion = ''

          this.$refs.modalEliminar.hide();

          this.refreshNotificaciones();

          
        } else {
          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          } else if (response.data.code == 504) {
            this.$refs.modalEliminar.hide();

          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
    },


    deletePhone(){


      this.$swal({
        title: '¿Seguro que deseas eliminar este número?',
        text: `Dejarás de recibir notificaciones a este número`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {


          return this.$https.post('/profile/deleteWhatsapp2/', { tokenAuth: this.tokenAuth,  userId: this.userId }).then(response => response.data).catch(error => {
            this.$swal.showValidationMessage(
              error,
            )
          })
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then(result => {

        this.$swal.close()

        if(result.isConfirmed){

           if (result.value.code == 200) {

            

          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


          this.refreshNotificaciones();
    
                  } else {
                    this.$toast.error(result.value.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })

                    if (result.data.code == 401) {
                      
                      localStorage.removeItem('userData')

                      // Redirect to login page
                      this.$router.push({ name: 'auth-login' })
                    }
                  }

        }
        



   
      })


    },

    verifyCode(codigo) {
      this.$swal({

        html:'<img style="margin-left:15px;width:20px; " src="/img/loading.svg" alt="Loading" /> Verificando código, espere',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        position: 'center',
      })

      const userId_json = {
        userId: this.userId, pin: this.pinPhone, codigo: codigo, celular: this.phoneNumber,
      }
      const user_string = JSON.stringify(userId_json)

      const userEncripted = this.$encryptBackoffice.encrypt(user_string)

      this.$https.post('/profile/confirmWhatsapp/', { tokenAuth: this.tokenAuth, data: userEncripted }).then(response => {
        this.$swal.close()

        if (response.data.code == 200) {

           const datos_json = JSON.parse(this.$userGlobal);

           datos_json.phone = this.phoneNumber
           Vue.prototype.$userGlobal= JSON.stringify(datos_json);



           localStorage.setItem('userData', JSON.stringify(datos_json))



          this.sendCode = ''
          this.pinPhone = ''
          this.phoneNumber = ''
          this.$refs.modalPhone.hide()

          this.refreshNotificaciones();
           this.$eventBus.$emit('reiniciarBot')

             this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          });

        
          this.$eventBus.$emit('reiniciarNotificacionesNavBar');

        } else {
          this.sendCode = ''

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        }
      }).catch(error => {
        this.sendCode = ''
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
    },
    refreshNotificaciones() {
      this.$eventBus.$emit('reiniciarNotificaciones')
    },

    onSubmit2(event) {
      event.preventDefault()

      const userId_json = { userId: this.userId, pin: this.pinPhone, celular: this.phoneNumber }
      const user_string = JSON.stringify(userId_json)

    

      this.isActivePhone = true

      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

      document.getElementById('btn_number').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Guardando, espere'

      this.$https.post('/profile/saveWhatsapp/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        this.isActivePhone = false

        document.getElementById('btn_number').innerHTML = 'Registrar número celular'

        if (response.data.code == 200) {

          
          const datos_json = JSON.parse(this.$userGlobal);

            datos_json.phone = this.phoneNumber
            Vue.prototype.$userGlobal= JSON.stringify(datos_json);



            localStorage.setItem('userData', JSON.stringify(datos_json))



            this.sendCode = ''
            this.pinPhone = ''
            this.phoneNumber = ''


            this.refreshNotificaciones();
            this.$eventBus.$emit('reiniciarBot')

              this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
            });


            this.$eventBus.$emit('reiniciarNotificacionesNavBar');
    


        } else {
          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pinPhone = ''
          } else if (response.data.code == 504) {
            this.phoneNumber = ''
          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
    },
        notificacionesEmail() {
      this.ifEmail = true
    }

  },
}
</script>

<style lang="scss">



.hasNotificacion{
      text-align: center;
    font-weight: bold;
    font-size: 15px;
}



</style>
