<template>

  <b-row>

    <b-col
                    cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                  v-if="loading"
                  align="center"
                >

                <b-row>

                  <b-col cols="4" sm="4" md="4" lg="4" xl="4">



                  </b-col>

                    <b-col cols="4" sm="4" md="4" lg="4" xl="4" align="center">

                  <b-skeleton type="avatar" width="60px" height="60px"></b-skeleton>


                  </b-col>

                  <b-col cols="4" sm="4" md="4" lg="4" xl="4">



                  </b-col>

                  <b-col cols="2" sm="2" md="2" lg="2" xl="2"></b-col>

                  <b-col cols="8" sm="8" md="8" lg="8" xl="8" style="margin-top: 10px;">
                    <b-skeleton width="100%"  height="10px"></b-skeleton>

                    </b-col>

                    <b-col cols="2" sm="2" md="2" lg="2" xl="2"></b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">


                      <b-skeleton width="100%"  height="40px"></b-skeleton>


                    </b-col>

                    <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 15px;">

                      <b-skeleton width="100%"  height="10px"></b-skeleton>

                      <b-skeleton width="100%"  height="30px"></b-skeleton>

                      </b-col>

                      <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 15px;">

                      <b-skeleton width="100%"  height="20px"></b-skeleton>

                      <table width="100%">
                                  <tr>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                  </tr>
                                </table>

                      </b-col>

                      <b-col
                              cols="4"
                                  sm="4"
                                  md="4"
                                  lg="4"
                                  xl="4"
                                > </b-col>


                              <b-col
                              cols="4"
                                  sm="4"
                                  md="4"
                                  lg="4"
                                  xl="4"
                                >

                                <b-skeleton width="100%"  height="40px"></b-skeleton>
                              
                              </b-col>


                              <b-col
                              cols="4"
                                  sm="4"
                                  md="4"
                                  lg="4"
                                  xl="4"
                                > </b-col>


                  


                </b-row>

                </b-col>

    <b-col
      v-else-if="code200"
      sm="12"
      md="12"
      lg="12"
      xl="12"
      align="center"
      style="padding: 0px;"
    >

    <b-col
          v-if="hasEmail"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          align="center"
        >
          <img

            alt="Todo ok"
            src="/img/ok.svg"
          >

          <p class="hasNotificacion">
            Ya tienes configurado notificaciones por email
          </p>
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body">
              <span> El email {{ email }} se encuentra actualmente habilitado para recibir notificaciones de pagos por correo electrónico.</span>
            </div>
          </b-alert>

        </b-col>

        <b-col
          v-else
          sm="12"
          md="12"
          lg="12"
          xl="12"
          align="center"
        >

          <img

            alt="No tiene email"
            src="/img/no_email.svg"
          >

          <p class="hasNotificacion">
            No tiene configurado un email para su cuenta
          </p>

          <b-form
            autocomplete="off"
            @submit.prevent="onSubmit"
          >

            <b-row>

              <b-col
                sm="12"
                md="12"
                lg="6"
                xl="6"
                align="center"
              >

                <p
                  class="textLabel"
                  style="text-align: center;margin-top: 10px; margin-bottom: 10px;"
                >
                  Ingrese su email (*):
                </p>

                <b-form-input
                  v-model="email"
                  type="email"
                  required
                  placeholder="Ingrese un email"
                />

              </b-col>

              <b-col
                sm="12"
                md="12"
                lg="6"
                xl="6"
                align="center"
              >

                <p
                  class="textLabel"
                  style="text-align: center; margin-top: 10px; margin-bottom: 10px;"
                >
                  Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

                <PincodeInput
                  v-model="pinEmail"
                  placeholder="-"
                  :length="6"
                  :secure="true"
                  required
                  :autofocus="false"
                />

              </b-col>

              <b-col
                sm="12"
                md="12"
                lg="3"
                xl="3"
                align="center"
              />

              <b-col
                sm="12"
                md="12"
                lg="6"
                xl="6"
                align="center"
              >

                <b-button
                  id="btn_email"
                  ref="btn_email"
                  type="submit"
                  variant="primary"
                  block
                  style="margin-top:10px"

                  :disabled="isActiveEmail"
                >

                  Verificar email

                </b-button>

              </b-col>

              <b-col
                sm="12"
                md="12"
                lg="3"
                xl="3"
                align="center"
              />

            </b-row>

          </b-form>

        </b-col>

    </b-col>

    <b-col
      v-else
      sm="12"
      md="12"
      lg="12"
      xl="12"
      align="center"
    >

       <feather-icon
             icon="FrownIcon"
             size="50"
             />
      <p class="errorTexto">
        Ocurrió un error al cargar los datos
      </p>

      <b-button
        id="btn_refresh"
        ref="btn_refresh"
        variant="primary"
        @click="refreshEmail"
      >

        Intentar de nuevo

      </b-button>

    </b-col>

        <b-modal
      ref="modalEmail"
      centered
      hide-footer
      title="Código de verificación de email"
    >
      <div class="d-block text-center">

        <p class="p_login">
          Ingrese el código de verificación enviado a su email
        </p>

        <PincodeInput
          v-model="sendCode2"
          placeholder="-"
          :length="6"
          :autofocus="false"
          :secure="true"
        />

      </div>

    </b-modal>

  </b-row>

</template>

<script>
import {
  BButton, BForm,BAlert,BFormInput, VBPopover, BFormGroup, BFormSelect, BSkeleton, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import PincodeInput from 'vue-pincode-input'
import Vue from 'vue'
export default {
  components: {
    PincodeInput,
    vSelect,
    BFormSelect,
BAlert,

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSkeleton,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId', 'tokenAuth'],
  data() {
    return {
      code200: false,
      loading: true,
       pinEmail: '',
            email: '',
      isActiveEmail: false,
     hasEmail: false,
           sendCode2: '',


    }
  },
  computed: {

  },
  watch: {

     sendCode2(newValue) {
      if (newValue.length == 6) {
        this.verifyCode2(newValue)
      }
    }

  },
  mounted() {

 
      const userId_json = { userId: this.userId }
    const user_string = JSON.stringify(userId_json)

    const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

    this.$https.post('/profile/checkHasEmail/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {
      if (response.data.code == 200) {
        this.loading = false

        this.email = response.data.email
    

        if (this.email === '') {
          this.hasEmail = false
        } else {
          this.hasEmail = true
        }

        this.code200 = true
      } else {
        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        if (response.data.code == 401) {
          
          localStorage.removeItem('userData')



          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
          this.code200 = false
        }
      }
    }).catch(error => {

  
      this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    })
    
  },
  methods: {

    refreshEmail() {
      this.$eventBus.$emit('reiniciarEmail')
    },
      onSubmit(event) {
      event.preventDefault()

      const userId_json = { userId: this.userId, pin: this.pinEmail, email: this.email }
      const user_string = JSON.stringify(userId_json)

      this.isActiveEmail = true

      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

      document.getElementById('btn_email').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Verificando, espere'

      this.$https.post('/profile/sendCodeVerificacionEmail/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        this.isActiveEmail = false

        document.getElementById('btn_email').innerHTML = 'Verificar email'

        if (response.data.code == 200) {
          this.sendCode2 = ''

          this.$refs.modalEmail.show()
        } else {
          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pinEmail = ''
          } else if (response.data.code == 504) {
            this.email = ''
          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
    },
    verifyCode2(codigo) {
      this.$swal({

        html:
              '<img style="margin-left:15px;width:20px; " src="/img/loading.svg" alt="Loading" /> Verificando código, espere',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        position: 'center',
      })

      const userId_json = {
        userId: this.userId, pin: this.pinEmail, codigo, email: this.email,
      }
      const user_string = JSON.stringify(userId_json)

      const userEncripted = this.$encryptBackoffice.encrypt(user_string)

      this.$https.post('/profile/confirmEmail/', { tokenAuth: this.tokenAuth, data: userEncripted }).then(response => {
        this.$swal.close()

        if (response.data.code == 200) {
      

           const datos_json = JSON.parse(this.$userGlobal);

          datos_json.email = this.email

          Vue.prototype.$userGlobal= JSON.stringify(datos_json);


          localStorage.setItem('userData', JSON.stringify(datos_json))

 

          this.sendCode2 = ''
          this.pinEmail = ''
          this.email = ''
          this.$refs.modalEmail.hide()

          this.refreshEmail();

          this.eqcoins(response.data.eqpoints, "activar las notificaciones por email", "");
          this.$eventBus.$emit('reiniciarNotificacionesNavBar');

          
             this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
          
        } else {
          this.sendCode2 = ''

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        }
      }).catch(error => {
        this.sendCode2 = ''
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
    },

  },
}
</script>

<style lang="scss">




</style>
