<template>

<b-modal
      ref="modalPhoto"
      centered
      hide-footer
      size="sm"
      title="Cambiar foto de perfil"
      @hidden="closeModalFotoPerfil"
    >
      <div class="d-block text-center">

        <b-form
          autocomplete="off"
          @submit.prevent="updatePhotoProfile"
        >

          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom: 10px;"
            >
            
          <b-alert
                variant="primary"
                show
                
                >
                <div class="alert-body">
                        
                  <p style="font-size:12px;text-align: center;"> La imagen debe tener una tamaño con aspecto de 1:1, por ejemplo 500px x 500px, 800px x 800px, 1200px x 1200px etc</p>


                </div>
                </b-alert>
                
                </b-col>
            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom: 10px;"
            >

            
              <b-form-file
                id="profilePhoto"
                v-model="profilePhoto"
                required
                :disabled="isActiveFoto"
                accept="image/jpeg, image/jpg"
                placeholder="Seleccione una imagen o suéltelo aquí..."
                drop-placeholder="Suelte la imagen aqui..."
                @change="previewImage1"
              />

              <p style="margin: 0;font-size: 12px;display:flex">{{ uploadValue1.toFixed()+"%" }}
                <progress
                  style="width:100%"
                  :value="uploadValue1"
                  max="100"
                />  </p>

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

              <p
                class="textLabel"
                style="text-align: center;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

              <PincodeInput
                v-model="pinPhoto"
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :disabled="isActiveFoto"
                required
              />

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

              <b-button
                id="btn_foto"
                ref="btn_foto"
                type="submit"
                variant="primary"
                :disabled="isActiveFoto"
                block
              >

                Cambiar foto de perfil

              </b-button>

            </b-col>

          </b-row>
        </b-form>

      </div>

    </b-modal>
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard, BForm, BFormFile, BAlert
      } from 'bootstrap-vue'
      import PincodeInput from 'vue-pincode-input'
      import 'firebase/compat/storage'
      import Vue from 'vue'
      
      
      export default {
        components: {
            PincodeInput,
            BForm, BFormFile,
            
          BButton,
          BRow,
          BCol,
          BCard,
          Vue,
          BAlert
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId', 'tokenAuth'],
        data() {
      
      
          return {
      
            profilePhoto: [],
            pinPhoto: '',
            isActiveFoto: false,
            uploadValue1: 0,

          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

            this.pinPhoto = ''

                this.isActiveFoto = false



                this.uploadValue1 = 0

                this.profilePhoto = []

                this.$refs.modalPhoto.show()
      
        },
        methods: {

            closeModalFotoPerfil(){
                this.$eventBus.$emit('reiniciarModalFotoPerfil')
            },  

            previewImage1(event) {
            const FileSizeBanner = event.target.files[0].size / 1024 / 1024
            let foto=event.target.files[0];

            if (FileSizeBanner > 3) {
              this.$refs.modalPhoto.hide()

              this.$toast.error('La imagen debe pesar menos de 3mb', {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            }else{
              

                      let imgwidth=0;
                      let imgheight=0;

                        var _URL = window.URL || window.webkitURL;
                      
                        var img = new Image();
                      
                        img.onload = () => {
                            // here you got the width and height
                            imgwidth = img.width;
                          imgheight = img.height;
                          if(Number(imgwidth) != Number(imgheight)){

                        
                            this.profilePhoto = [];
                            this.$toast.error('La imagen debe tener una tamaño con aspecto de 1:1, por ejemplo 500px por 500px, 800px por 800px, 1200px por 1200px etc', {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })
                  
                    }

                  };
                  img.src = _URL.createObjectURL(foto);

        

      }
    },
            
                updatePhotoProfile(event) {
                event.preventDefault()

                this.isActiveFoto = true
                this.uploadValue1 = 0

                const d1 = new Date();

                let nameFile= this.userId+"_"+d1.getTime()+"_"+this.profilePhoto.name;
                let dir= 'profilePhoto/'+this.userId;

                  const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).put(this.profilePhoto);

               
                storageRef.on('state_changed', snapshot => {
                    this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

                    document.getElementById('btn_foto').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Subiendo foto'
                }, error => {
                    this.$toast.error("Ocurrió un error inesperado".message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                    })

                    this.isActiveFoto = false
                    document.getElementById('btn_foto').innerHTML = 'Cambiar foto de perfil'
                },
                () => {
                    this.uploadValue1 = 100
                    storageRef.snapshot.ref.getDownloadURL().then(url => {
                    const data_json = { userId: this.userId, url, pin: this.pinPhoto }
                    const data_string = JSON.stringify(data_json)

                    const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

                    document.getElementById('btn_foto').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando foto'

                    this.$https.post('/profile/updatePhotoProfile/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
                        
                       
                        if (response.data.code == 200) {
                        this.pinPhoto = ''
                        this.profilePhoto = []
                        this.isActiveFoto = false

                        this.photo = url
                    

                        const datos_json = JSON.parse(this.$userGlobal);

                            datos_json.photoUser = url


                            Vue.prototype.$userGlobal= JSON.stringify(datos_json);

                            localStorage.setItem('userData', JSON.stringify(datos_json));


                            this.$toast.success(response.data.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            });

                            this.$eventBus.$emit('updateFotoPerfil', url)


                        document.getElementById('btn_foto').innerHTML = 'Cambiar foto de perfil'
                        this.$refs.modalPhoto.hide()
                        } else {
                        this.$toast.error(response.data.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                        })

                        if (response.data.code == 401) {
                            this.$refs.modalPhoto.hide()

                            
                            localStorage.removeItem('userData')



                            
                            

                    

                            
                            

                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                        }
                        }
                    }).catch(error => {
                        this.$toast.error("Ocurrió un error inesperado: " + error, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })
                    })
                    })
                })
                },
  
        
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      